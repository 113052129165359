<template>
    <div>
      <v-card v-if="!loading">
        <v-toolbar
            color="#4ECDC4"
            dark
            flat
        ><span class="text-h6">名前の変更</span>
        </v-toolbar>
      
        <v-card-text>
          ファイルの新しい名前を入力してください。
          <v-container>
            <v-row>
              <v-col
                cols="12"                     
                md="10"
              >
                <v-text-field
                  v-model="renameItemName"
                  :rules="[rules.sameName,rules.sameNameOther]"
                  :label="this.name"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          
        </v-card-text>
         
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ma-2"
              :disabled="loading"
              outlined
              color="#78909C"   
              @click="close">キャンセル
          </v-btn>
          <v-btn 
            color="#1DE9B6"   
            class="white--text"
            @click="renameItem" 
            :disabled="!this.enableRename" 
            >変更
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
       <v-card  v-else-if="isError" >
          <DialogError
            v-on:closeErrorEmit="close"
            text="エラーが発生し,名前の変更が完了しませんでした。"
          />
       </v-card>
       <v-card v-else
            color="#4ECDC4"
            dark
          >
          <v-card-text>
            変更中...
            <br>

            <v-progress-linear
              indeterminate
              color="white"
              
            ></v-progress-linear>
          </v-card-text>
        </v-card>

      </div>
      

</template> 

<script>
import axios from "axios"
const DialogError = () => import('@/components/DialogError.vue');
export default {
  name: 'DialogRename',
  props:["name","id","type","typeName","desserts"],
  components: {
    DialogError
  },
  data () {
    return {
      renameItemName : null,
      loading:false,
      enableRename:false,
      isOverlap:false,
      isError:false,
      dicName: (this.type=="navi") ? "naviName" : "fileName",
      dataName:(this.type=="navi") ? "NaviName" : "FileName",
      rules: {
        sameName: value => value != this.name || '名前が変更されていません',
        sameNameOther: value =>  !this.isOverlap && (value!=this.name) || '他のものと名前が重複しています。',
      },
    }
  },
  computed: {
    renameTitle () {
      return this.name
    },
  },

  watch : {
    renameItemName: function(){
      this.isOverlap = this.desserts.some((u) => u[this.dicName] == this.renameItemName )
      this.enableRename = !this.isOverlap && (this.name != this.renameItemName) && this.renameItemName; 
    }
  },

  methods: {

    //指定されたアイテムの変更
    renameItem () {
      if(!this.enableRename){ 
        this.close(); 
        return 0; 
      }
      this.loading = true;
      axios({
        method: "PUT",
        url:`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/${this.type}s/${this.id}`,
        headers: {"X-Api-Key": process.env.VUE_APP_KEY,"SessionId": this.$store.state.auth.login.sessionId,"Content-Type":"application/json" },
        data:{[this.dataName]:this.renameItemName},
      }).then(() => {
        this.loading = false;
        this.$emit('renameItemExeEmit',this.renameItemName)
        this.renameItemName = null;
      }).catch(reason => {
        console.log(reason.status)
        this.renameItemName = null;
        this.isError = true;
      });
    },

    close (){
      this.renameItemName = null;
      this.$emit('closeRenameEmit')
      this.isError = false;
      this.loading = false;
    }



 
  },
}

</script>
